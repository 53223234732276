<template>
    <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="validate">
        <v-container grid-list-xl>
            <v-layout wrap>
                <v-flex xs12>
                    <v-switch v-model="item.enable" :label="$t('Habilitado')" />
                </v-flex>
                <v-flex xs12>
                    <v-text-field :label="$t('Nome')" v-model="item.name" :rules="[rules.required, rules.min(3)]" />
                </v-flex>
                <v-flex xs12 sm6>
                    <v-text-field :label="$t('Desconto')" v-model="item.discount" :rules="[rules.required]" suffix="%" />
                </v-flex>

                <v-flex xs12 sm6>
                    <v-text-field v-model="item.cnpj" :rules="[rules.cnpj, rules.cnpjValidatorDigit]" label="CNPJ" v-mask="'##.###.###/####-##'" return-masked-value />
                </v-flex>

                <v-flex xs12 mt-8> <Address :item="item" /></v-flex>

                <v-flex xs12>
                    <v-btn :loading="lock" :disabled="!valid" color="success" @click="validate">{{ $t("Salvar") }}</v-btn>
                </v-flex>
            </v-layout>
        </v-container>
    </v-form>
</template>

<script>
import { mask } from "vue-the-mask";
import rules from "@/helpers/rules";
import Address from "@/components/form/Address";

export default {
    directives: { mask },
    components: { Address },
    props: {
        itemToEdit: {
            type: Object,
        },
        lock: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            rules,
            valid: true,
            itemClean: {
                name: "",
                cnpj: "",
                cepOk: true,
                discount: 0,
                cep: "",
                state: "",
                city: "",
                district: "",
                address: "",
                houseNumber: "",
                complement: "",
                enable: true,
            },
            item: { ...this.itemClean },
        };
    },

    watch: {
        itemToEdit: {
            immediate: true,
            handler(newVal) {
                if (this.$refs.form) {
                    this.$refs.form.reset();
                }

                this.$nextTick(() => {
                    if (newVal == null) {
                        this.item = { ...this.itemClean };
                    } else {
                        this.item = { ...newVal };
                    }
                });
            },
        },
    },

    methods: {
        validate() {
            if (this.$refs.form.validate()) {
                this.$emit("save", this.item);
            }
        },
    },

    mounted() {
        if (this.$refs.form) {
            this.$refs.form.reset();
        }
    },
};
</script>